<template>
  <div>
    <c-card class="cardClassDetailForm" title="상세">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- <c-btn 
            v-if="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="assessPlan"
            :mappingType="mappingType"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveAssessPlan"
            @btnCallback="saveCallback" /> -->
            <c-btn v-if="editable&&!disabled" label="저장" icon="save" @btnClicked="saveResult" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :readonly="true"
            label="심사일"
            name="date"
            v-model="result.date">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :readonly="true"
            label="피심사팀"
            name="deptName"
            v-model="result.deptName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
          <c-text
            :editable="editable"
            :readonly="true"
            label="심사내용"
            name="col1"
            v-model="result.col1">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="5"
            label="심사 결과 요약"
            name="auditResult"
            v-model="result.auditResult">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="5"
            label="심사 지적사항"
            name="auditErrorList"
            v-model="result.auditErrorList">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="5"
            label="기타사항"
            name="etc"
            v-model="result.etc">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      title="실시결과 및 결론 목록"
      :columns="grid.columns"
      :data="result.list"
      :gridHeight="grid.height"
      :editable="editable&&!disabled"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="id"
    >
      <!-- @linkClick="linkClick" -->
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="제외" icon="remove" @btnClicked="deleteResult" />
          <c-btn v-if="editable&&!disabled" label="추가" icon="add" @btnClicked="addResult" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-action-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
    action: {
      type: Object,
      default: () => ({
        id: '',  // 일련번호
        plantCd: '',
        col1: '', // 심사구분
        col2: '', // 심사 준비 요청사항
        col3: '', // 심사 목적 및 범위
        regDtStr: '',
        regUserName: '',
        auditMonthDepts: [], // 심사일정 - 피심사팀에 따른
        auditTeams: [], // 심사팀
        distributeTeams: [], // 배포부서
        checklistResult: [],
      }),
    },
    result: {
      type: Object,
      default: () => ({
        date: '',
        deptName: '',
        auditResult: '',
        auditErrorList: '',
        etc: '',
        col1: '',
        list: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'deptCd' },
        ],
        columns: [
          {
            name: 'task',
            field: 'task',
            label: '대상업무',
            align: 'left',
            style: 'width:150px',
            sortable: true,
            type: 'textarea'
          },
          {
            name: 'resultConclusion',
            field: 'resultConclusion',
            label: '심사 결과 및 결론',
            align: 'left',
            style: 'width:400px',
            sortable: true,
            type: 'textarea'
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            style: 'width:100px',
            sortable: true,
            type: 'textarea'
          },
        ],
        data: [],
        height: '500px',
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    reg() {
      return this.action.regUserName + ' / ' + this.action.regDtStr
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
    },
    saveResult() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addResult() {
      if (!this.result.list) this.result.list = [];
      this.result.list.splice(0, 0, {
        id: '',
        task: '', // 대상업무
        resultConclusion: '', // 심사 결과 및 결론
        remark: '', // 비고
      })
    },
    deleteResult() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '제외하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>